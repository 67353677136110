@font-face {
    font-family: 'Muller';
    src: url('../fonts/ofont.ru_Muller.ttf') format('woff');
    font-weight: normal;
    font-style: normal;
}

.list-first {
    font-family: 'Muller', sans-serif;
    font-size: 16px;
    font-weight: 700;
}

.list-first div {
    font-size: 12px;
    font-weight: 200;
}

.happy-hacking-container {
    border: 4px solid #3F2DB8;
    border-radius: 4px;
    background-color: #101318;
    color: white;
    padding: 10px;
    text-align: center;
}

@media (max-width: 768px) {
    .footer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 41px 24px;
   }

   ul {
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
   }

   .time {
    width: auto 0;
   }
}
@media(max-width:480px) {
    .time{
        padding-left: 24px;
        padding-right: 24px;
    }
}
@media (max-width: 768px){
    .foot-list ul .list-first{
        margin-left: 0 !important;
        /* margin-right: 0 !important; */
    }
}

@media(max-width: 550px){
    .footer-logo a img{
        width: 78px;
    }
}