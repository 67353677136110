@import url(../../../node_modules/tailwindcss/base.css);
@import url(../../../node_modules/tailwindcss/components.css);
@import url(../../../node_modules/tailwindcss/utilities.css);
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


@font-face {
  font-family: 'Monument Extended';
  src: url('../fonts/MonumentExtended-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
    font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('../img/jpg/Main-page-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  padding: 0 136px;
}

svg {
  display: flex;
  align-items: center;
}

.mySwiper4 {
  width: 70%;
  height: 100%;
  position: unset !important;
}

.mySwiper4 .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 124px;
}

.mySwiper4 .swiper-card {
  width: 100% !important;
  height: 394px;
  padding: 24px 0;
  background: #090f24;
}

.mySwiper4 .swiper-card figure {
  width: 124px;
  height: 124px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mySwiper4 .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mySwiper4 .swiper-button-prev {
  left: 8.5%;
  background: linear-gradient(153.63deg, #004E9B 3.71%, #3860D9 123.44%);
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.mySwiper4 .swiper-button-next {
  right: 8.5%;
  background: linear-gradient(153.63deg, #004E9B 3.71%, #3860D9 123.44%);
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.mySwiper4 .swiper-button-next::after {
  font-size: 16px;
  color: #fff;
}

.mySwiper4 .swiper-button-prev::after {
  font-size: 16px;
  color: #fff;
}

.mySwiper4 .swiper-button-disabled {
  pointer-events: fill !important;
  cursor: not-allowed !important;
}
.mySwiper4 .swiper-pagination{
  display: none;
  bottom: -34px !important;
}
.mySwiper4 .swiper-pagination-bullet{
  background-color: #fff !important;
  opacity: 1 !important;
}
.mySwiper4 .swiper-pagination-bullet-active{
  background-color: #3860D9 !important;
}



.mySwiper3 {
  width: 100%;
  height: 100%;
  padding: 0 136px !important;
}

.mySwiper3 .swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.mySwiper3 .swiper-slide img {
  display: block;
  width: 145px;
    height: 72px;
  object-fit: contain;
}


.swal2-popup {
  background-color: #fff !important;
}

.swal2-success-circular-line-left,
.swal2-success-fix,
.swal2-success-circular-line-right {
  background: none !important;
}

.swal2-title {
  font-size: 24px !important;
  color: var(--Gray-2, #4F4F4F) !important;
}

.swal2-html-container {
  font-size: 14px !important;
  color: var(--Gray-3, #828282) !important;
  font-family: "Muller";
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.12) !important;
  border-radius: 16px !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(6.9px) !important;
  -webkit-backdrop-filter: blur(6.9px) !important;
}


.header-container {
  @apply flex justify-between items-center pt-[32px] px-[136px] sm:px-[24px] sm:pt-[30px] sm:pb-[12px];
}

.footer {
  @apply bg-black text-white flex justify-around items-center py-10 sm:pl-[24px];
}

.date {
  @apply text-center text-white rounded py-5 px-10 border-4 border-[#3659CB] bg-[#060809] font-bold sm:w-[205px] sm:h-[45px] sm:text-[12px] sm:p-0 sm:flex sm:justify-center sm:items-center;
}

.swiper-card {
  @apply w-[376px] h-[475px] text-black rounded border-2 border-[#3352B9] sm:w-[253px] sm:h-[307px]
}




@media(max-width:1300px) {
  .mySwiper4 {
    width: 86%;
  }

  .mySwiper4 .swiper-button-next {
    right: 2%;
  }

  .mySwiper4 .swiper-button-prev {
    left: 2%;
  }
}
@media(max-width:1100px) {
  .santa {
    right: 20px !important;
  }
}
@media(max-width:996px) {
  .container{
    padding: 0 50px;
  }
  .header-container{
    padding: 32px 50px 0 50px;
  }
  .header-container ul li{
    display: none;
  }
  .header-container ul li:last-child{
    display: block;
  }
  .santa {
    display: none;
  }

  .mySwiper4 .swiper-button-next {
    right: 1%;
  }

  .mySwiper4 .swiper-button-prev {
    left: 1%;
  }
}
@media(max-width:768px) {
  .mySwiper3 {
    padding: 0 24px !important;
  }

  .mySwiper3 .swiper-slide img {
    display: block;
    /* width: 100%;
    height: 100%; */
    object-fit: contain;

  }

  .mySwiper4 {
    width: 70%;
  }

  .mySwiper4 .swiper-button-next {
    right: 3%;
  }

  .mySwiper4 .swiper-button-prev {
    left: 3%;
  }

  .table_main {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .header-container{
    padding: 14px 50px;
  }
  .mobilClock{
    width: 100% !important;
  }
}
@media (max-width: 550px) {
  .container{
    padding: 0 24px;
  }
  .header-container{
    padding: 14px 24px 0 24px !important;
  }
  .mySwiper2 .swiper-slide {
    width: 253px !important;
    height: 307px !important;
    margin-right: 12px !important;
  }

  .mySwiper2 .swiper-wrapper {
    height: 330px !important;
  }
  .mySwiper4 .swiper-button-next{
      display: none;
  }
  .mySwiper4 .swiper-button-prev{
    display: none;
  }
  .mySwiper4{
    width: 90%;
  }
  .mySwiper4 .swiper-pagination{
    display: block;
  }
  .header-container{
    padding: 14px 30px;
  }
}
@media(max-width: 390px){
  .header-container{
    padding: 14px;
  }
  /* .header-container img{
    width: 60px !important;
  } */
  .header-container .header_reg{
    width: 100px !important;
    font-size: 15px !important;
    height: 40px !important;
  }
}

  
.error-messages {
  font-size: 12px;
  font-family: "Muller", "sans-serif";
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}