.card-item{
    border: 1px solid #3860D9;
    border-radius: 5px;
    box-shadow: 0px 4px 14px 0px rgba(0,56,255,0.2);
-webkit-box-shadow: 0px 4px 14px 0px rgba(0,56,255,0.2);
-moz-box-shadow: 0px 4px 14px 0px rgba(0,56,255,0.2);
    color: #fff;
    padding: 24px;
    width: calc((100% - 40px) / 3);
}
.event-container{
    padding: 0 135px;
    margin-top: 130px;
}

@media(max-width: 1024px){
    .card-item{
        width: calc((100% - 50px) / 2);
    }
    .event-container{
        padding: 0 50px;
    }
}

@media(max-width: 768px){
    .card-item{
        width: calc((100%) / 1);
    }
}

@media(max-width: 550px){
    .event-container{
        padding: 0 24px;
        margin-top: 20px;
    }
}