.staff-card{
    @apply border-[2px] text-white bg-[#111620] border-[#482FDE] p-4 w-[342px] h-[710px] lg:h-[696px] sm:w-full sm:h-auto
}

.staff-title{
    @apply text-white text-[28px] font-Inter text-base lg:text-xl font-medium lg:font-bold mb-4
}

@media(max-width:480px){
    .staff-group h2{
        font-size: 16px;
        font-weight: 400 !important;
    }
    .staff-card{
        width: 100%;
        height: auto;
        font-family: 'Muller', 'sans-serif';
    }
    .staff_card p{
        font-size: 12px;
    }

}