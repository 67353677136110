.questions-center {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #fff;
}

.questions-center-row {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #fff;
}
.questions-center-row-top {
    display: flex;
    align-items: center;
    gap: 32px;
    cursor: pointer;
    padding: 28px 0;
}
.questions-center-row-top-circle {
    width: 42px;
    height: 42px;
    flex-shrink: 0;
    background: #8780bb;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.questions-center-row-top-circle svg {
    color: #fff;
}
.questions-center-row-bottom p {
    padding: 8px 28px 36px 28px;
}
.faq-container{
    padding: 0 135px;
    margin-bottom: 237px;
}
@media(max-width: 1024px){
    .faq-container{
        padding: 0 50px;
    }
}
@media(max-width: 550px){
    .questions-center-row-top{
        padding: 18px 0;
        gap: 8px;
    }
    .questions-center-row-bottom p{
        padding: 0px 18px 18px 0px;
    }
    .faq-container{
        margin-bottom: 55px;
        padding: 0 24px;
    }
}