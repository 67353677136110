.clock {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-left: 0;
    height: 85px;
    width: 400px;
    color: #fff;
    /* text-shadow: 1px 1px 4px; */
    padding: 2px 6px;
    border: 4px solid #3F2DB8;
    border-radius: 4px;
    background-color: #101318;
 }
 
 .clock section {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     padding: 10px;    
 }
 
 .clock section p {
     font-size: 24px;
 }
 
 .clock section small {
     color: #E0E0E0;
     text-shadow: none;
     font-size: 12px;
 }


 .happy-hacking-container {
    border: 4px solid #3F2DB8;
    border-radius: 4px;
    background-color: #101318;
    color: white;
    padding: 10px;
    text-align: center;
}

@media ( max-width: 996px ) {
    .clock {
        width: 350px;
    }
    
    .clock section p {
        font-size: 18px;
    }

    .clock section small {
        font-size: 12px;
    }

}

 
 @media(max-width:550px){
     .clock{
        width: 230px;
        height: 65px;
       }
       .clock section p{
         font-size: 12px ;
       }
       .clock section small{
         font-size: 8px;
       }
     
}
 @media(max-width:320px){
     .clock{
         width: 200px;
     }
     .clock section p{
         font-size: 10px;
     }
     .clock section small{
         font-size: 5px;
     }
}