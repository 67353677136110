@font-face {
    font-family: 'Muller';
    src: url('../fonts/ofont.ru_Muller.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Fontfabric\ -\ MullerBold.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Monument Extended';
    src: url('../fonts/MonumentExtended-Regular.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


h2 {
    font-family: 'Monument Extended' , sans-serif;
}


.tabel_body{
    display: flex;
    gap: 14vw;
    
}


.tabel_body div h4{
    color: #3860D9;
    font-family: "Muller";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
}
.tabel_date{
    margin-bottom: 8px;
}
.table_main_box{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.tabel_title{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.tabel_title h5{
    width: 50%;
    visibility: hidden;
}
.tabel_body{
    width: 100%;
}
.tabel_body_first{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.tabel_body_first__row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tabel_body_first__row p{
    font-family: "Muller";
    font-size: 1.52vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.tabel_body_first__row h5{
    font-family: "Muller";
    font-size: 1.52vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 50%;
}
table .start, .end {
    color: rgba(56, 96, 217, 1);
}

table .table-width {
    width: 274px;
}

table .start-width {
    width: 160px;
}

@media(max-width:1200px){
    .tabel_body{
        gap: 170px;
    }
}
@media(max-width:1100px){
    .table_main{
        padding-left: 100px !important;
        padding-right: 100px !important;
    }
    .tabel_body{
        gap: 120px;
    }
}
@media(max-width:996px){
    .tabel_body{
        gap: 50px;
    }
}
@media(max-width:768px){
    .tabel_body p{
        font-size: 15px !important;
    }
    .tabel_body_first{
        gap: 30px;
    }
}
@media (max-width: 550px){
    .tabel_title{
        position: absolute;
        top: -80px;
    }
    .whitespace-nowrap{
        white-space: normal;
    }
    .tabel_body p{
        font-size: 12px !important;
    }
    .tabel_body h4{
        font-size: 12px !important;
    }
    .tabel_date p{
        font-size: 16px !important;
    }
    .tabel_body_first{
        margin-top: 30px;
    }
    .tabel_body{
        justify-content: space-between;
        gap: 10px !important;
    }
    .table_main_box{
        height: auto;
        padding-bottom: 30px;
    }
}
@media(max-width:768px){
    .table_main{
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .tabel_body{
        gap: 80px;
    }
    .table_main_box{
        padding-left: 24px;
        padding-right: 24px;
    }
    .tabel_title{
        font-size: 16px;
    }
    table{
        font-size: 12px !important;
    }
    thead{
        font-size: 12px !important;
    }
}
@media(max-width:480px){
    .tabel_body_first__row h5{
        font-size: 2.5vw;
    }
    
    .first_th{
        display: none !important;
    }
    tr{
        display: flex;
        flex-wrap: wrap;
        width: 220px;
    }
    .table_main_box{
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
}
@media(max-width:390px){
    .tabel_body_first__row h5{
        font-size: 3vw;
    }
}
@media(max-width:320px){
    .tabel_body p{
        font-size: 10px !important;
    }    
}