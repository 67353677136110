@font-face {
  font-family: 'Muller';
  src: url('../fonts/ofont.ru_Muller.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}

.waveback {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: white;
  width: 55%;
  align-items: flex-end;

}

.font-of-text {
  font-family: "Monument Extended";
  font-size: 42px;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  text-align: start;
}

.texts-left {
  gap: 20px;
}

.custom-button {
  font-family: 'Muller';
  border-radius: 2px;
  width: 430px;
  height: 57px;
  color: #3860D9;
  border: none;
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
}

.custom-button:hover {
  color: white;
}

.custom-button-start {
  font-family: 'Muller';
  text-align: start;
  border-radius: 2px;
  width: 430px;
  height: 57px;
  color: #3860D9;
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  border: none;
}

.custom-button-start:hover {
  color: white;
}

.custom-button-sponsor {
  font-family: 'Muller';
  border-radius: 2px;
  width: 430px;
  height: 57px;
  color: #3860D9;
  border: none;
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
}

.custom-button-sponsor:hover {
  color: white
}

.all-background {
  background-color: #4733DE;
}

.close-button {
  background-color: #4733DE;
}

.head-sector {
  width: 836px;
  height: 400px;
  margin: auto;
  background-color: #482FDE;
}
.modal_main{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal_main_group p{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.modal_main h3{
  margin-top: 60px;
}
.close_btn{
  position: absolute;
  right: 24px;
  top: 24px;
}
.close_btn svg{
  color: #fff;
  width: 20px;
  height: 20px;
}
.home-banner-bg{
  position: absolute;
  right: 207px;
  top: 120px;
  height: 550px;
}
.home-banner-responisve-bg{
  display: none;
  position: absolute;
  right: 207px;
  top: 120px;
  height: 550px;
}
@media(max-width: 1200px){
  .home-banner-bg{
    right: 130px;
  }
}
@media(max-width: 1024px){
  .home-banner-bg{
    display: none;
  }
  .home-banner-responisve-bg{
    display: block;
    right: 0;
  }
}
@media(max-width:768px) {
  .header_title {
    font-size: 20px;
  }
  .modal_main h3{
    margin-top: 30px;
  }
  .date {
    font-size: 12px;
    width: 205px;
    height: 45px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

@media (max-width: 550px) {
  .home-banner-responisve-bg{
    width: 383px;
    top: 14px;
  }
  .modal_box{
    width: 400px;
  }
  .modal_main_group p a button{
    width: 300px;
  }
  .modal_main h3{
    font-size: 12px;
  }
  .close_btn{
    top: 20px;
    right: 20px;
  }
}
@media(max-width:480px){
  .home-banner-responisve-bg{
    top: 100px;
    width: unset;
    /* right: -80px; */
    height: 254px;
  }
  .modal_main button{
    width: 220px !important;
  }
  .main_bg{
    display: none;
  }

  .modal_box{
    width: 100% !important;
  }
  .modal_wrapper{
    padding: 0 20px !important;
  }
 
}

.modal-bg{
background: rgba(0, 0, 0, 0.12);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(6.9px);
-webkit-backdrop-filter: blur(6.9px);
}