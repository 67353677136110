.leaderboard-wrapper {
    margin-top: 46px;
    padding: 0 136px;
}

.title {
    margin-bottom: 16px;
}

.teams {
    display: grid;
    row-gap: 8px;
}

.teams .team:nth-child(2n+1) {
    background-color: #1F2037;
}

.teams .team:nth-child(2n) {
    background-color: #252740;
}

.team {
    display: grid;
    height: 64px;
    padding: 0 28px;
    grid-template-columns: 1fr 2fr auto;
    align-items: center;
    border-radius: 4px;
}

.team.headers {
    grid-template-columns: 1fr 1.94fr auto;
    .team-info-name, .team-time, .team-score {
        color: #7D7E9F;
    }
}

.team-info {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    column-gap: 16px;
}

.team-info .team-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 32px;
}
.team-badge{
    position: relative;
}
.team-badge .nums{
    position: absolute;
    color: #fff ;
}
.team-badge img {
    width: 100%;
}

.team-badge .number {
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    position: absolute;
}


.team-info-name {
    font-size: 18px;
    font-weight: 500;
    color: #BDBDBD;
}

.team-time {
    font-size: 16px;
    color: #BDBDBD;
}

.team-score {
    font-size: 15px;
    color: #BDBDBD;
}

@media screen and (max-width: 1200px) {
    .leaderboard-wrapper {
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media screen and (max-width: 1000px) {
    .team {
        grid-template-columns: 1fr 1fr auto;
    }

    .team.headers {
        grid-template-columns: 1.06fr 1fr auto;
    }
}

@media(max-width: 550px){
    .leaderboard-wrapper{
        padding: 0 24px;
    }
    .team{
        height: 48px;
        padding: 0 16px;
    }
    .team-info-name, .team-time, .team-score{
        font-size: 14px;
    }
    .team-badge img{
        width: 21px;
        height: 28px;
    }
    .team-info-name, .team-time, .team-score{
        font-size: 14px;
    }
}