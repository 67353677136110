@font-face {
    font-family: 'Muller';
    src: url('../fonts/ofont.ru_Muller.ttf') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Monument Extended';
    src: url('../fonts/MonumentExtended-Regular.otf');
    font-weight: 700;
}
.simple{
    position: relative;
}
.slider-simple{
    padding-top: 160px;
    padding-bottom: 300px;
}
.slider-simple h2{
    color: #FFF;
    text-align: center;
    font-family: 'Inter';
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 54px;
}
.team_lists ul{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    margin-top: 24px;
    list-style: none;
}
.team_lists h4{
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    margin-top: 16px;
}
.list_circle{
    width: 10px;
    height: 10px;
    border-radius: 20px;
    background: #3860D9 ;
    
}
.team_lists ul li{  
    font-family: 'Muller';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    color: #fff;
}
.team_list{
    display: flex;
    gap: 18px;
}
.team_lists ul li::marker{
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 20px;
    
}

.card-back{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.cardss{
    display: flex !important;
    gap: 15px;

}

.member-list{
    @apply ml-[36px] mr-[36px] text-[16px] text-[#4F4F4F] list-disc marker:text-blue-700 sm:text-[14px]
}
.team-image{
    @apply w-[152px] h-[152px] rounded-full sm:w-[90px] sm:h-[90px] object-cover
}
.team-member-title{
    @apply py-10 text-white text-[42px] text-center sm:text-[24px] md:text-[24px]
}

@media(max-width:550px){
    .slider-simple h2{
        margin-bottom: 32px;
    }
    .team_lists ul{
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    .team_lists ul li{
        font-size: 10px !important;
        width: 125px;
    }
    .swiper-card_box{
        margin-top: 12px !important;
    }
    .team_lists h4{
        font-size: 14px;
    }
    .slider-simple{
        padding-top: 66px;
        padding-bottom: 80px;
    }
    .slider-simple h2{
        font-size: 24px;
    }
}
