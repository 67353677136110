.team-card-container{
    padding: 0 135px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;  
    gap: 20px;
}
.load-more-btn{
    padding: 14px 16px;
    background-color: #005EBE;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    display: none;
}
.team-card-item{
    background-color: #111419;
    border-radius: 4px;
    -moz-box-shadow: 0px 4px 23px 0px rgba(56,96,217,0.2);
    box-shadow: 0px 4px 23px 0px rgba(56,96,217,0.2);
    -webkit-box-shadow: 0px 4px 23px 0px rgba(56,96,217,0.2);
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 20px 0 20px 12px;
    flex: 0 0 calc((100% - 60px) / 4);
}
.team-card-item p {
    width: 175px;
}
.team-card-item figure{
    width: 72px;
    height: 72px;
    border-radius: 50%;
    overflow: hidden;
}
.team-card-item figure img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media(max-width: 1024px){
    .team-card-container{
        padding: 0 50px;
    }
    .team-card-item{
    flex: 0 0 calc((100% - 60px) / 3);

    }
}

@media(max-width: 550px){
    .team-card-container{
        padding: 0 24px;
    }
    .team-card-item{
        flex: 0 0 calc((100% - 0px) / 1);
    }
    .load-more-btn{
        display: block;
    }
}